import * as React from "react";
import ChartViewsDropdown from "../elements/ChartViewsDropdown";
import FilterButton from "../elements/FilterButton";

export default function CampaignChartSettings() {
	return (
		<section className="px-5 lg:px-[30px] mt-[30px]">
			<div className="flex justify-between">
				<ChartViewsDropdown label="Chart views" />
				<FilterButton label="Norms" />
			</div>
		</section>
	);
}
