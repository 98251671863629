import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ClearButton from "../elements/ClearButton";

export default function MetricBadges({ onClick, onClear, metrics, metric, learningIndicators, heatmap }) {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const tooltipTimeoutRef = useRef(null);

	const handleMouseEnter = () => {
		tooltipTimeoutRef.current = setTimeout(() => {
			setTooltipVisible(true);
		}, 500); // Delay tooltip appearance by 500ms
	};

	const handleMouseLeave = () => {
		clearTimeout(tooltipTimeoutRef.current);
		setTooltipVisible(false);
	};

	useEffect(() => {
		return () => {
			// Cleanup on component unmount
			if (tooltipTimeoutRef.current) {
				clearTimeout(tooltipTimeoutRef.current);
			}
		};
	}, []);

	const checkMetricLearning = (item, learningProperty) => {
		return learningIndicators?.some(
			(learningObj) => learningObj.emotions[0] === item.id && learningObj[learningProperty] && learningObj[learningProperty] !== null
		);
	};

	const isMetricWithHeatmap = (item) => {
		return item?.id && heatmap?.length > 0;
	};

	return (
		<div className='flex flex-wrap items-center space-x-5'>
			<div className={`relative basis-full`}>
				<div className='rounded-md prevent-scrollbar'>
					<div className='flex flex-wrap items-center justify-start min-w-full gap-2 pt-2 md:pt-0'>
						{metrics &&
							metrics.map((item, index) => {
								const isMetricWithKeyLearning = checkMetricLearning(item, "key_learning");
								const isMetricWithOptimizations = checkMetricLearning(item, "optimizations");
								const shouldRenderHeatmapDot = isMetricWithHeatmap(item);
								return (
									<div key={index} className={"badgeElement"}>
										<div className={`absolute flex justify-center space-x-1 transform -translate-x-1/2 -top-[3px] left-1/2`}>
											<div
												className={`relative w-[6px] h-[6px] bg-[#78D969] rounded-full transition-all duration-500 ease-in-out before:bg-white/80 before:rounded-lg before:h-[10px] before:w-[10px] before:absolute before:z-[-1] before:content-[''] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 ${
													isMetricWithKeyLearning ? "block" : "hidden"
												}`}
											/>
											<div
												className={`relative w-[6px] h-[6px] bg-[#FFDF00] rounded-full transition-all duration-500 ease-in-out before:bg-white/80 before:rounded-lg before:h-[10px] before:w-[10px] before:absolute before:z-[-1] before:content-[''] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 ${
													isMetricWithOptimizations ? "block" : "hidden"
												}`}
											/>
											<div
												className={`relative w-[6px] h-[6px] bg-[#3D2EFF] rounded-full transition-all duration-500 ease-in-out before:bg-white/80 before:rounded-lg before:h-[10px] before:w-[10px] before:absolute before:z-[-1] before:content-[''] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 ${
													shouldRenderHeatmapDot ? "block" : "hidden"
												}`}
											/>
										</div>

										<button
											onClick={() => onClick(item, true)}
											className={`px-3 py-3 font-medium rounded-md transition-colors border text-[14px] xl:text-[16px] shadow-sm bg-white hover:bg-[#F7F7FB] active:bg-gray-200 border-[#D6DCDE]`}
											style={{
												backgroundColor:
													metric.findIndex((obj) => obj === item) !== -1 ? item.colors.background_color : undefined,
												color: metric.findIndex((obj) => obj === item) !== -1 ? item.colors.border_color : undefined,
												borderColor: metric.findIndex((obj) => obj === item) !== -1 ? item.colors.border_color : undefined,
											}}
										>
											<div className='flex items-center h-5 space-x-3'>
												<div className='leading-none whitespace-nowrap'>{item.name}</div>
												{item.tooltip && (
													<div
														className={"tooltip-trigger"}
														onMouseEnter={handleMouseEnter}
														onMouseLeave={handleMouseLeave}
													>
														<svg
															width='16px'
															height='16px'
															viewBox='0 0 20 20'
															version='1.1'
															xmlns='http://www.w3.org/2000/svg'
															style={{
																stroke: item.colors.border_color || "black",
															}}
														>
															<g id='DASHBOARD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
																<g
																	id='DETAIL-infos'
																	transform='translate(-1431.000000, -395.000000)'
																	stroke='currentColor'
																	strokeWidth='1.5'
																>
																	<g id='Group-2-Copy-2' transform='translate(1432.000000, 384.000000)'>
																		<g id='Info_duotone_line' transform='translate(0.000000, 12.000000)'>
																			<circle
																				id='Oval'
																				fillRule='nonzero'
																				transform='translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) '
																				cx='9'
																				cy='9'
																				r='9'
																			/>
																			<line x1='9' y1='9' x2='9' y2='14.4' id='Path' strokeLinecap='round' />
																			<line x1='9' y1='5.5' x2='9' y2='4.6' id='Path' strokeLinecap='round' />
																		</g>
																	</g>
																</g>
															</g>
														</svg>
														{tooltipVisible && (
															<div className={"tooltip"}>
																<div className={"tooltip-inner"}>{item.tooltip}</div>
															</div>
														)}
													</div>
												)}
											</div>
										</button>
									</div>
								);
							})}
						<ClearButton onClick={() => onClear()} label='Clear' />
					</div>
				</div>
			</div>
		</div>
	);
}
