import * as React from "react";
import CampaignDropdown from "../elements/CampaignDropdown";
import SearchInput from "../elements/SearchInput";

export default function CampaignToolbar({ campaign, campaigns }) {
	return (
		<section id='toolbar' className='bg-[#F7F7FB]'>
			<div className='container mx-auto'>
				<div className='flex flex-wrap items-center py-6'>
					<div className='basis-full lg:basis-2/3 max-lg:order-2'>
						<CampaignDropdown campaign={campaign} campaigns={campaigns} />
					</div>
					<div className='basis-full lg:basis-1/3 max-lg:order-1'>
						<div className='max-lg:mb-5'>
							<SearchInput white />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
