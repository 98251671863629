import * as React from "react";
import closeIcon from "../../images/close-icon.svg";

export default function ClearButton({ label, onClick }) {
	return (
		<div className='basis-auto'>
			<button
				onClick={() => onClick()}
				className='flex items-center text-base text-[#8A8A8A] rounded-lg py-2.5 pl-2 pr-3 transition-colors hover:bg-[#F7F7FB] active:bg-gray-200 '
			>
				<div className={"basis-auto pr-2.5"}>
					<img src={closeIcon} width={"17px"} height={"17px"} alt='Clear' className={"max-w-none"} />
				</div>
				<div className={"basis-auto"}>{label}</div>
			</button>
		</div>
	);
}
