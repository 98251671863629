import * as React from "react";
import ClearButton from "../elements/ClearButton";

export default function DeviceBadges({ devices, onClick, onClear, device }) {
	return (
		<div className='flex flex-wrap items-center space-x-5'>
			<div className={`relative basis-full`}>
				<div className='rounded-md prevent-scrollbar'>
					<div className='flex flex-wrap items-center justify-start min-w-full gap-2 pt-2 md:pt-0'>
						{devices &&
							devices.map((item, index) => (
								<div key={index} className={"badgeElement"}>
									<button
										onClick={() => onClick(item, true)}
										className={`px-3 py-3 font-medium rounded-md transition-colors border text-[14px] xl:text-[16px] shadow-sm bg-white hover:bg-[#F7F7FB] active:bg-gray-200 border-[#D6DCDE]`}
										style={{
											backgroundColor:
												device.findIndex((obj) => obj === item) !== -1 ? item.colors.background_color : undefined,
											color: device.findIndex((obj) => obj === item) !== -1 ? item.colors.border_color : undefined,
											borderColor: device.findIndex((obj) => obj === item) !== -1 ? item.colors.border_color : undefined,
										}}
									>
										<div className='flex items-center h-5 space-x-3'>
											<div className='leading-none whitespace-nowrap'>{item.name}</div>
										</div>
									</button>
								</div>
							))}
						<ClearButton onClick={() => onClear()} label='Clear' />
					</div>
				</div>
			</div>
		</div>
	);
}
