import React, { useState, useRef, useEffect } from "react";

function HelpButton({ definitions, helpView, setHelpView }) {
	const [helpBox, setHelpBox] = useState(false);
	const helpBoxRef = useRef(null); // Create a ref for the help box

	// Effect to add event listener to the document
	useEffect(() => {
		function handleClickOutside(event) {
			if (helpBoxRef.current && !helpBoxRef.current.contains(event.target)) {
				setHelpBox(false); // Close the help box if click is outside
			}
		}

		// Add event listener when the help box is open
		if (helpBox) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		// Cleanup the event listener
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [helpBox]); // Effect runs when `helpBox` changes

	console.log(definitions);
	return (
		<div className={"relative"}>
			<button className={"py-2 px-3"} onClick={() => setHelpBox(true)}>
				<div className={"flex items-center space-x-1.5"}>
					<div className={"basis-auto"}>
						<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
							<g id='DASHBOARD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
								<g id='DETAIL-infos' transform='translate(-1431.000000, -395.000000)' stroke='#000000' strokeWidth='1.5'>
									<g id='Group-2-Copy-2' transform='translate(1432.000000, 384.000000)'>
										<g id='Info_duotone_line' transform='translate(0.000000, 12.000000)'>
											<circle
												id='Oval'
												fillRule='nonzero'
												transform='translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) '
												cx='9'
												cy='9'
												r='9'
											/>
											<line x1='9' y1='9' x2='9' y2='14.4' id='Path' strokeLinecap='round' />
											<line x1='9' y1='5.5' x2='9' y2='4.6' id='Path' strokeLinecap='round' />
										</g>
									</g>
								</g>
							</g>
						</svg>
					</div>
					<div className={"basis-auto hidden leading-none md:block"}>Help</div>
				</div>
			</button>

			{helpBox && (
				<div ref={helpBoxRef} className={"p-6 bg-[#EDEFF0] absolute z-[10] w-[300px] md:w-[400px] right-0 mt-3 text-left help-box"}>
					<div className={"flex border-b border-[#D6DCDE] mb-6 items-center justify-between"}>
						<div className={"basis-auto"}>
							<div className={"flex"}>
								<div className={"basis-auto pr-4"}>
									<div
										onClick={() => setHelpView(definitions[0].id)}
										className={`text-[16px] text-[#000000] cursor-pointer pb-2.5 helpSwitch ${
											helpView === definitions[0].id ? "active" : ""
										}`}
									>
										{definitions[0].title}
									</div>
								</div>
							</div>
						</div>
						<div className={"basis-auto pb-2.5"}>
							<div className={"cursor-pointer"} onClick={() => setHelpBox(false)}>
								<svg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg'>
									<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
										<g transform='translate(-1467.000000, -470.000000)' stroke='#000000' strokeWidth='1.5'>
											<g transform='translate(1083.000000, 432.514719)'>
												<g transform='translate(385.000000, 38.485281)'>
													<line x1='0' y1='0' x2='8' y2='8' id='Path' />
													<line
														x1='0'
														y1='0'
														x2='8'
														y2='8'
														id='Path'
														transform='translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) '
													/>
												</g>
											</g>
										</g>
									</g>
								</svg>
							</div>
						</div>
					</div>

					<div className={"flex help-text"}>
						<div className={"basis-full"} dangerouslySetInnerHTML={{ __html: definitions[0].content }} />
					</div>
				</div>
			)}
		</div>
	);
}

export default HelpButton;
