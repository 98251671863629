import * as React from "react";
import { useState, useRef } from "react";
import useOnClickOutside from "../../style/utils/useClickOutside";
import ChevronIcon from "./ChevronIcon";

export default function PublisherDropdown({ publishers, onClick, publisher }) {
	// component states
	const [open, setOpen] = useState(false);
	const ref = useRef();

	// click outside handler
	useOnClickOutside(ref, () => setOpen(false));

	// click inside handler
	const handleClick = () => {
		setOpen(!open);
	};

	React.useEffect(() => {
		if (open) {
			ref.current.scrollTop = 0;
		}
	}, [open]);

	return (
		<div className='flex flex-wrap items-center'>
			<div className='basis-full'>
				<button
					tabIndex={0}
					onClick={handleClick}
					className={`flex items-center justify-between w-full space-x-3 px-3 py-2 font-medium rounded-md border shadow-sm transition-colors ${
						publisher[0]?.id
							? "text-[#1A414A] bg-[#D0ECF8]  border-[#B2DBEC]   hover:bg-[#b8deec] active:bg-[#afd9e8] "
							: "text-[#000000] bg-white hover:bg-[#F7F7FB] active:bg-gray-200 border-[#D6DCDE]"
					}`}
				>
					<div>{publisher[0]?.id ? "Publisher: " + publisher[0].name : "Publisher"}</div>
					<ChevronIcon />
				</button>
				<div
					ref={ref}
					className={`absolute z-[1] w-[300px] px-2 py-3 mt-2 rounded-md border border-[#D6DCDE] overflow-auto overscroll-none max-h-80 bg-white shadow-lg ${
						open === true ? "block" : "hidden"
					}`}
				>
					<ul className='flex flex-col items-center'>
						{publishers &&
							publishers.map((item, index) => (
								<li key={index} className='w-full'>
									<button
										className={`w-full text-left transition  px-3 py-2 flex items-center ${index === 0 ? "" : "border-t"} ${
											publisher.findIndex((obj) => obj === item) !== -1 ? "text-[#000000]" : "text-[#595959]"
										} border-[#EFEFEF] hover:text-[#000000]`}
										onClick={() => onClick(item)}
									>
										<div className={"basis-auto pr-2.5"}>
											<div
												className={`checkmark w-3.5 h-3.5 rounded border ${
													publisher.findIndex((obj) => obj === item) !== -1 ? "border-[#3D2EFF] active" : "border-[#D6DCDE]"
												}`}
											/>
										</div>
										<div className={"basis-auto"}>{item.name}</div>
									</button>
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	);
}
