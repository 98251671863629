import * as React from "react";
import CampaignChart from "./CampaignChart";
import CampaignChartSettings from "../sections/CampaignChartSettings";

// renderHeatmapValue fn calculates the range for the yellow, green, and red color by considering both directions (plus and minus) around the campaign average value, based on the specified threshold for each metric type ("Attention", "Synchrony", "Engagement", "Emotion").
//
// Here's how it works for each metric type:
// - For "Attention": The yellow color range is from numCampaignAvg - 0.2 to numCampaignAvg + 0.2.
// - For "Synchrony": The yellow color range is from numCampaignAvg - 0.25 to numCampaignAvg + 0.25.
// - For "Engagement": The yellow color range is from numCampaignAvg - 0.2 to numCampaignAvg + 0.2.
// - For "Emotion": The yellow color range is from numCampaignAvg - 0.3 to numCampaignAvg + 0.3.
//
// The code uses the threshold variable to determine these ranges. If the numValue (the heatmap value for a specific metric) falls within the range defined by numCampaignAvg ± threshold, the background color class is set to "bg-yellow-100", indicating that the value is within the expected range around the campaign average, considering the metric-specific threshold.
// - If numValue is greater than numCampaignAvg + threshold, it indicates a significantly higher performance than the average, and the color is set to green ("bg-green-100").
// - If numValue is less than numCampaignAvg - threshold, it indicates a significantly lower performance than the average, and the color is set to red ("bg-red-100").
// - Otherwise, the value is within the defined range around the average, and the color is set to yellow ("bg-yellow-100"), indicating a normal or expected performance relative to the campaign average and the specified threshold.

const formatHeatmapValue = (value) => (value ? parseFloat(value).toFixed(3) : "-");

const renderHeatmapValue = (heatmapValue, campaignAvgValue, metricType) => {
	const numValue = parseFloat(heatmapValue);
	const numCampaignAvg = parseFloat(campaignAvgValue);
	let bgColorClass = "bg-transparent"; // Default background color
	let threshold = 0; // Default threshold

	switch (metricType) {
		case "Attention":
			threshold = 0.2;
			break;
		case "Synchrony":
			threshold = 0.25;
			break;
		case "Engagement":
			threshold = 0.2;
			break;
		case "Emotion":
			threshold = 0.3;
			break;
		default:
			break;
	}

	if (numValue > numCampaignAvg + threshold) {
		bgColorClass = "bg-green-100";
	} else if (numValue < numCampaignAvg - threshold) {
		bgColorClass = "bg-red-100";
	} else {
		bgColorClass = "bg-yellow-100";
	}

	const formattedHeatmapValue = formatHeatmapValue(heatmapValue);

	return <span className={`px-1.5 py-0.5 ${bgColorClass} rounded-md`}>{formattedHeatmapValue}</span>;
};
export default function CampaignCard({ video, chartData, details, heatmap, campaignAvg, learning, campaignBenchmark, metrics, tooltips }) {
	const defaultData = {
		attention: "-",
		emotion: "-",
		engagement: "-",
		synchrony: "-",
	};

	const campaignAvgData = campaignAvg && heatmap?.length > 0 ? campaignAvg : defaultData;
	const campaignBenchmarkData = campaignBenchmark && heatmap?.length > 0 ? campaignBenchmark : defaultData;

	function getLabel(x) {
		const label = tooltips?.find((label) => label.key === x);
		return label?.value;
	}

	return (
		<section className='mt-5 mb-8'>
			<CampaignChart campaignVideo={video} chartData={chartData} details={details} />
			<CampaignChartSettings />
			{learning?.some((item) => item.key_learning && item.key_learning.trim() !== "") && (
				<div id={"key_learning"} className='border border-[#E1E7EA] rounded-md p-[25px] mt-5 mx-5 lg:mx-[30px]'>
					<div
						style={{ backgroundColor: `#ABF79F` }}
						className={`rounded-full w-fit uppercase font-bold text-[12px] leading-none px-2.5 py-1.5`}
					>
						Key Learnings
					</div>

					<div className='[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:pb-5'>
						{learning.map((item, index) => {
							const emotionNames = (item.emotions || [])
								.map((emotionId) => {
									const emotion = metrics?.find((metric) => metric?.id === emotionId);
									return emotion ? emotion.name : null;
								})
								.filter((name) => name !== null)
								.join(", ");
							return (
								item.key_learning &&
								item.key_learning.trim() !== "" && (
									<div key={index} className='mt-5'>
										{emotionNames && <div className='mb-2 font-medium'>{emotionNames}</div>}
										<div
											className='mt-2 [&_h2]:text-[16px] [&_h2]:text-black [&_h2:not(:first-child)]:mt-5 [&_h2]:font-medium [&_p]:text-[#444444] [&_p]:text-sm [&_p]:mt-1 [&_p_strong]:text-black [&_p_strong]:font-medium [&_a]:text-black [&_a]:font-medium [&_a]:underline [&_li]:list-disc [&_ul]:mt-1 [&_li]:ml-5 [&_li]:text-[#444444] [&_li]:text-sm'
											dangerouslySetInnerHTML={{ __html: item.key_learning }}
										/>
									</div>
								)
							);
						})}
					</div>
				</div>
			)}
			{learning?.some((item) => item.optimizations && item.optimizations.trim() !== "") && (
				<div id={"optimizations"} className='border border-[#E1E7EA] rounded-md p-[25px] mt-5 mx-5 lg:mx-[30px]'>
					<div
						style={{ backgroundColor: `#FFE73D` }}
						className={`rounded-full w-fit uppercase font-bold text-[12px] leading-none px-2.5 py-1.5`}
					>
						Optimizations
					</div>
					<div className='[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:pb-5'>
						{learning?.map((item, index) => {
							const emotionNames = (item.emotions || [])
								.map((emotionId) => {
									const emotion = metrics?.find((metric) => metric?.id === emotionId);
									return emotion ? emotion.name : null;
								})
								.filter((name) => name !== null)
								.join(", ");
							return (
								item.optimizations &&
								item.optimizations.trim() !== "" && (
									<div key={index} className='mt-5'>
										{emotionNames && <div className='mb-2 font-medium'>{emotionNames}</div>}
										<div
											className='[&_h2]:text-[16px] [&_h2]:text-black [&_h2:not(:first-child)]:mt-5 [&_h2]:font-medium [&_p]:text-[#444444] [&_p]:text-sm [&_p]:mt-1 [&_p_strong]:text-black [&_p_strong]:font-medium [&_a]:text-black [&_a]:font-medium [&_a]:underline [&_li]:list-disc [&_ul]:mt-1 [&_li]:ml-5 [&_li]:text-[#444444] [&_li]:text-sm'
											dangerouslySetInnerHTML={{ __html: item.optimizations }}
										/>
									</div>
								)
							);
						})}
					</div>
				</div>
			)}
			{heatmap?.length > 0 && (
				<div className={"grid px-5 lg:px-[30px] pt-5 sm:pt-[25px] mt-3 rounded-lg bg-white"}>
					<div className='grid-flow-col overflow-x-auto sm:rounded-lg'>
						<table className='w-full text-sm text-left text-gray-500'>
							<thead className='text-[14px] text-gray-400 bg-white'>
								<tr>
									<th scope='col'></th>
									<th scope='col'></th>
									<th scope='col' className='px-6 py-3 font-medium min-w-48'>
										Dimensions
									</th>
									<th scope='col' className='px-6 py-3 font-medium min-w-32'>
										Attention
									</th>
									<th scope='col' className='px-6 py-3 font-medium min-w-32'>
										Synchrony
									</th>
									<th scope='col' className='px-6 py-3 font-medium min-w-32'>
										Emotion
									</th>
									<th scope='col' className='px-6 py-3 font-medium min-w-32'>
										Engagement
									</th>
								</tr>
							</thead>
							<tbody>
								{heatmap.map((item, index) => (
									<tr key={index}>
										<td></td>
										<td></td>
										<td className='px-6 py-4 text-black border-t min-w-48'>
											<table>
												<tbody>
													<tr className=''>
														<td>
															<span className='mr-4 text-gray-500'>Audiences:</span>
														</td>
														<td>{item.demographic.name}</td>
													</tr>
													<tr className=''>
														<td>
															<span className='mr-4 text-gray-500'>Devices:</span>
														</td>
														<td>{item.device.name}</td>
													</tr>
													<tr className=''>
														<td>
															<span className='mr-4 text-gray-500'>Publisher:</span>
														</td>
														<td>{item.publisher.name} </td>
													</tr>
													<tr className=''>
														<td>
															<span className='mr-4 text-gray-500'>Frequency:</span>
														</td>
														<td>{item.frequency.name} </td>
													</tr>
												</tbody>
											</table>
										</td>
										<td className='px-6 py-4 text-black border-t min-w-32'>
											{renderHeatmapValue(item.attention, campaignAvgData.attention, "Attention")}
										</td>
										<td className='px-6 py-4 text-black border-t min-w-32'>
											{renderHeatmapValue(item.synchrony, campaignAvgData.synchrony, "Synchrony")}
										</td>
										<td className='px-6 py-4 text-black border-t min-w-32'>
											{renderHeatmapValue(item.emotion, campaignAvgData.emotion, "Emotion")}
										</td>
										<td className='px-6 py-4 text-black border-t min-w-32'>
											{renderHeatmapValue(item.engagement, campaignAvgData.engagement, "Engagement")}
										</td>
									</tr>
								))}
								<tr className='h-4 '>
									<div className='relative'>
										<div className='absolute left-0 -top-10'>
											<div className='flex items-center space-x-1.5'>
												<div className='relative bg-[#3D2EFF] text-white rounded-full uppercase font-bold text-[12px] w-fit leading-none px-3 py-2'>
													Heatmap
												</div>
												<div className='flex items-center text-[13px] text-[#939393] font-medium leading-none'>
													<div className={"tooltip-trigger relative w-fit"}>
														<svg
															width='16px'
															height='16px'
															viewBox='0 0 20 20'
															version='1.1'
															xmlns='http://www.w3.org/2000/svg'
														>
															<g id='DASHBOARD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
																<g
																	id='DETAIL-infos'
																	transform='translate(-1431.000000, -395.000000)'
																	stroke='#939393'
																	strokeWidth='1.5'
																>
																	<g id='Group-2-Copy-2' transform='translate(1432.000000, 384.000000)'>
																		<g id='Info_duotone_line' transform='translate(0.000000, 12.000000)'>
																			<circle
																				id='Oval'
																				fillRule='nonzero'
																				transform='translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) '
																				cx='9'
																				cy='9'
																				r='9'
																			/>
																			<line x1='9' y1='9' x2='9' y2='14.4' id='Path' strokeLinecap='round' />
																			<line x1='9' y1='5.5' x2='9' y2='4.6' id='Path' strokeLinecap='round' />
																		</g>
																	</g>
																</g>
															</g>
														</svg>
														<div className={"tooltip tooltip-vh"}>
															<div className={"tooltip-inner"}>{getLabel("video-heatmap-tooltip-text")}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</tr>
								<tr className='bg-white font-medium text-black text-[18px] border-t-[3px] border-[#E1E7EA]'>
									<td className='pr-6 pl-3 py-4 min-w-[290px]'>Campaign Weighted Average</td>
									<td></td>
									<td></td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignAvgData.attention)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignAvgData.synchrony)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignAvgData.emotion)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignAvgData.engagement)}</td>
								</tr>
								<tr className='bg-gray-100 font-medium text-black text-[18px] border-t-[3px] border-[#E1E7EA]'>
									<td className='pr-6 pl-3 py-4 min-w-[290px]'>Category Benchmark</td>
									<td></td>
									<td></td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignBenchmarkData.attention)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignBenchmarkData.synchrony)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignBenchmarkData.emotion)}</td>
									<td className='px-6 py-4'>{formatHeatmapValue(campaignBenchmarkData.engagement)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}
		</section>
	);
}
