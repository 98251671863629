import React from "react";

export default function LoaderDetails() {
	return (
		<div className='w-full'>
			<div className={"basis-full pt-10 pb-10"}>
				<div className='animate-pulse'>
					<div className='mb-4 bg-gray-200 rounded w-full max-w-[600px] h-14' />
					<div className='h-[1px] bg-gray-200 rounded' />
				</div>
				<div className='flex flex-col gap-10 mt-4 sm:justify-between sm:flex-row animate-pulse'>
					<div className='w-full h-12 bg-gray-200 rounded basis-auto max-w-48' />
					<div className='flex flex-col gap-10 sm:flex-row basis-auto'>
						<div className='w-48 h-12 bg-gray-200 rounded' />
						<div className='w-40 h-12 bg-gray-200 rounded' />
						<div className='w-32 h-12 bg-gray-200 rounded' />
						<div className='w-32 h-12 bg-gray-200 rounded' />
					</div>
				</div>
			</div>

			<div className='bg-gray-50 animate-pulse rounded-lg py-[30px] px-5 lg:px-[30px]'>
				<div className='animate-pulse'>
					<div className='flex flex-wrap gap-3 mb-4 animate-pulse'>
						<div className='bg-gray-200 rounded w-28 h-9' />
						<div className='bg-gray-200 rounded w-28 h-9' />
						<div className='bg-gray-200 rounded w-28 h-9' />
					</div>
				</div>
				<div className='h-[1px] bg-gray-200 rounded animate-pulse' />
				<div className='flex flex-wrap gap-3 mt-4 animate-pulse'>
					<div className='bg-gray-200 rounded w-28 h-9' />
					<div className='bg-gray-200 rounded w-28 h-9' />
					<div className='bg-gray-200 rounded w-28 h-9' />
					<div className='bg-gray-200 rounded w-28 h-9' />
					<div className='bg-gray-200 rounded w-28 h-9' />
				</div>
				<div className='w-48 h-8 mt-20 bg-gray-200 rounded animate-pulse' />
				<div className='grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 animate-pulse'>
					<div className='h-56 bg-gray-200 rounded' />
					<div className='h-56 bg-gray-200 rounded' />
				</div>
			</div>
		</div>
	);
}
