import * as React from "react";
import Layout from "../components/layout";
import Detail from "../components/modules/detail";
import Login from "../components/modules/Login";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { StateContext } from "../state/global-state";

export default function CampaignDetails({ location }) {
	const params = new URLSearchParams(location.search);
	const campaign = params.get("campaign");
	const video = params.get("video");

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const checkUserToken = () => {
		const accessToken = localStorage.getItem("access_token");
		if (!accessToken || accessToken === "undefined") {
			setIsLoggedIn(false);
		} else {
			setIsLoggedIn(true);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		checkUserToken();
	}, []);

	return (
		<React.Fragment>
			{isLoading && !isLoggedIn && (
				<div className='flex items-center justify-center h-screen'>
					<div className='w-32 h-32 border-t-2 border-b-2 border-[#3D2EFF] rounded-full animate-spin'></div>
				</div>
			)}
			{!isLoading && !isLoggedIn && <Login />}
			{!isLoading && isLoggedIn && (
				<StateContext.Consumer>
					{({
						emotions,
						emotionsByDemography,
						demographics,
						handleCampaignEmotionClick,
						handleClear,
						setEmotions,
						setEmotionsByDemography,
						setDemographics,
						setDemographicsByEmotion,
					}) => {
						return (
							<>
								<Helmet>
									<title>Glassview Origin - Campaigns</title>
								</Helmet>
								<Layout>
									<Detail
										demographics={demographics}
										emotions={emotions}
										emotionsByDemography={emotionsByDemography}
										setEmotions={setEmotions}
										setEmotionsByDemography={setEmotionsByDemography}
										setDemographics={setDemographics}
										setDemographicsByEmotion={setDemographicsByEmotion}
										campaignId={campaign}
										videoId={video}
									/>
								</Layout>
							</>
						);
					}}
				</StateContext.Consumer>
			)}
		</React.Fragment>
	);
}
